import { addNavMenuSection } from '@vendure/admin-ui/core'

export default [
  addNavMenuSection(
    {
      id: 'delivery',
      label: 'Delivery',
      items: [
        {
          id: 'delivery',
          label: 'Delivery',
          routerLink: ['/extensions/delivery-manager'],
          // Icon can be any of https://core.clarity.design/foundation/icons/shapes/
          icon: 'cursor-hand-open',
        },
      ],
    },
    'settings'
  ),
]
